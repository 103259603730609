




































































































































































import { namespace } from 'vuex-class';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { ReviewStoreActions, ReviewStoreGetters } from '@/store/review.store';
import Review from '@/models/Review.model';
import { DealStoreActions, DealStoreGetters } from '@/store/deal.store';
import Deal from '@/models/Deal.model';
import MediaFile from '@/interfaces/MediaFile.interface';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import DealTrackingData from '@/models/DealTrackingData.model';
import { DealTrackingDataStoreActions, DealTrackingDataStoreGetters } from '@/store/dealTrackingData.store';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';
import { ExpiryReason } from '@/enum/ExpiryReason.enum';
import { ROUTE_DEAL } from '@/router/routes';
import { DisplayType } from '@/enum/DisplayType';

const ReviewStore = namespace('review');
const DealStore = namespace('deals');
const TrackingStore = namespace('trackingData');

@Component({
  components: {
    DealReviewsComponent: () => import(
      /* webpackChunkName: "DealReviewsComponent" */
      '@/components/DealReviews.component.vue'
    ),
    EditDealComponent: () => import(
      /* webpackChunkName: "EditDealComponent" */
      '@/components/EditDeal.component.vue'
    ),
    MediaCarouselComponent: () => import(
      /* webpackChunkName: "MediaCarouselComponent" */
      '@/components/MediaCarousel.component.vue'
    ),
    KpiSummaryComponent: () => import(
      /* webpackChunkName: "KpiSummaryComponent" */
      '@/components/KpiSummary.component.vue'
    )
  }
})
export default class DealView extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ required: true })
  public dealId!: string;

  @ReviewStore.Action(ReviewStoreActions.GET_BY_DEAL)
  private getReviewsByDealAction!: (dealId: string) => Promise<Review[]>;

  @ReviewStore.Getter(ReviewStoreGetters.REVIEWS_BY_DEAL)
  private reviews!: Review[];

  @DealStore.Action(DealStoreActions.GET_BY_ID)
  private getDealByIdAction!: (id: string) => Promise<Deal>;

  @DealStore.Getter(DealStoreGetters.SELECTED_DEAL)
  private selectedDeal!: Deal;

  @DealStore.Action(DealStoreActions.GET_FILES_FOR_DEAL)
  private getFilesForDeal!: (deal: Deal) => Promise<MediaFile[]>;

  @DealStore.Getter(DealStoreGetters.FILES)
  private files!: MediaFile[];

  @DealStore.Action(DealStoreActions.GET_ICON)
  private getIconURLAction!: (dealId: string) => Promise<string>;

  @DealStore.Getter(DealStoreGetters.ICON_URL)
  private iconURL!: string;

  @DealStore.Action(DealStoreActions.UPDATE)
  private updateDealAction!: (deal: Deal) => Promise<Deal>;

  @TrackingStore.Action(DealTrackingDataStoreActions.GET_BY_DEAL)
  private getTrackingDataForDealAction!: (dealId: string) => Promise<DealTrackingData>;

  @TrackingStore.Getter(DealTrackingDataStoreGetters.ITEM_BY_DEAL)
  private trackingData!: DealTrackingData;

  @ReviewStore.Action(ReviewStoreActions.DELETE)
  private deleteReviewAction!: (reviewId: string) => Promise<any>;

  private showEditDialog: boolean = false;

  private isWaitingForFilesToLoad: boolean = false;
  private isLoadingReviews: boolean = false;
  private isLoadingTrackingData: boolean = false;
  private isLoadingUpdate: boolean = false;

  private DisplayType = DisplayType;

  @Watch('dealId', { immediate: true })
  private onDealIdChanged() {
    this.reloadData(true, true);
  }

  private async closeDialog(reload: boolean = false) {
    this.showEditDialog = false;
    if (reload) {
      if (this.dealId != this.selectedDeal.id) {
        await this.$router.push({ name: ROUTE_DEAL, params: { dealId: this.selectedDeal.id } });
      } else {
        this.reloadData(false, false);
      }
    }
  }

  /**
   * Checks if selectedDeal is set
   * and redirects to last page if not.
   * Retrieves all necessary data from database.
   */
  private async reloadData(updateTrackingData: boolean, updateReviews: boolean) {
    this.isWaitingForFilesToLoad = true;
    if (updateReviews) this.isLoadingReviews = true;
    if (updateTrackingData) this.isLoadingTrackingData = true;
    try {
      const deal = await this.getDealByIdAction(this.dealId);
      if (this.selectedDeal) {
        if (updateReviews) {
          await this.getReviewsByDealAction(this.dealId);
          this.isLoadingReviews = false;
        }
        
        await this.getIconURLAction(this.dealId);

        if (updateTrackingData) {
          await this.getTrackingDataForDealAction(this.dealId);
          this.isLoadingTrackingData = false;
        }
        
        await this.getFilesForDeal(this.selectedDeal);
      } else {
        this.goBack();
      }
    } catch (e) {
      this.handleAxiosError(e);
    } finally {
      this.isWaitingForFilesToLoad = false;
      this.isLoadingReviews = false;
      this.isLoadingTrackingData = false;
    }
  }

  private goBack() {
    this.$router.back();
  }

  private getProgressBarColor(value: number): string {
    if (value <= BaseMixin.THRESHOLD_RED) {
      return 'error';
    } else if (value >= BaseMixin.THRESHOLD_GREEN) {
      return 'success';
    } else {
      return 'warning';
    }
  }

  /**
   * Deal can only be edited if it is not deactivated because of an expired enddate or exceeded budget.
   * Otherwise, the deal needs to be reactivated.
   */
  get canEditDeal(): boolean {
    return this.selectedDeal.expiryReason != ExpiryReason.DATE_EXPIRED && this.selectedDeal.expiryReason != ExpiryReason.NO_REMAINING_BUDGET;
  }

  private async updateDeal(deal: Deal) {
    try {
      await this.updateDealAction(deal);
      await this.getDealByIdAction(this.selectedDeal.id);
    } catch (e) {
      this.handleAxiosError(e);
    } finally {
      this.isLoadingUpdate = false;
    }
  }

  private async activateDeal() {
    this.isLoadingUpdate = true;
    const dealCopy: Deal = this.selectedDeal.copy() as Deal;
    dealCopy.status = true;
    dealCopy.expiryReason = ExpiryReason.NOT_EXPIRED;
    this.updateDeal(dealCopy);
  }

  private async deactivateDeal() {
    const dealCopy: Deal = this.selectedDeal.copy() as Deal;
    dealCopy.status = false;
    dealCopy.expiryReason = ExpiryReason.MANUALLY_DISABLED;
    this.updateDeal(dealCopy);
  }

  private async deleteReview(reviewId: string) {
    try {
      this.isLoadingReviews = true;
      await this.deleteReviewAction(reviewId);
      await this.getReviewsByDealAction(this.dealId);
      await this.getDealByIdAction(this.dealId); // to update average rating
    } catch (e) {
      this.handleAxiosError(e);
    } finally {
      this.isLoadingReviews = false;
    }
  }
}
